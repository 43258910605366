import { render, staticRenderFns } from "./inspiration-card.vue?vue&type=template&id=4c2eb3ff&scoped=true&"
import script from "./inspiration-card.vue?vue&type=script&lang=js&"
export * from "./inspiration-card.vue?vue&type=script&lang=js&"
import style0 from "./inspiration-card.vue?vue&type=style&index=0&id=4c2eb3ff&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c2eb3ff",
  null
  
)

export default component.exports