<template>
  <div
    v-if="!isLoading"
    class="inspiration-detail mt-16 lg:mt-32"
    style="font-family: Montserrat"
  >
    <div class="w-full max-w-rifyo mx-auto">
      <div class="max-xl:px-7">
        <div class="py-4 flex align-center" @click="goBack()">
          <img
            alt="image"
            src="/playground_assets/arrow-left.svg"
            class="mr-4 cursor-pointer"
          />
          <span class="tracking-widest cursor-pointer"
            >Back To Inspiration</span
          >
        </div>
      </div>
    </div>
    <div
      class="w-full flex relative"
      :style="$mq === 'lg' ? `height: 640px;` : ``"
    >
      <div
        class="lg:hidden w-full lg:w-1/2"
        :style="`background-color: ${dataDetail.color}`"
      >
        <div class="inline-block lg:hidden container py-20 md:py-8 px-5">
          <div
            class="handwriting font-[#1f252c] text-shadow-sm leading-none max-lg:text-6xl lg:text-7xl"
            v-html="dataDetail.title"
            style="font-family: Lora; font-weight: 900"
          ></div>
          <!-- <div class="text-white font-normal leading-6 text-lg">{{ dataDetail.subtitle }}</div> -->
        </div>
      </div>
      <div
        class="hidden lg:inline-block w-full bg-cover h-640"
        :style="`background-image: url('${dataDetail.img_detail}')`"
      ></div>
      <div class="hidden lg:inline-block absolute w-full h-full">
        <div
          class="max-w-rifyo w-full h-full mx-auto flex flex-col py-16 justify-center"
        >
          <div class="w-full lg:w-6/12 px-7 xl:px-0">
            <div
              class="handwriting font-[#1f252c] text-shadow-sm leading-none max-lg:text-6xl lg:text-7xl"
              v-html="dataDetail.title"
              style="font-family: Lora; font-weight: 900"
            ></div>
            <!-- <div class="text-white font-normal leading-6 text-lg">{{ dataDetail.subtitle }}</div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="w-full max-w-rifyo mx-auto flex flex-col relative min-h-screen">
      <div class="relative max-xl:px-7">
        <div class="w-full lg:w-7/12 mx-auto py-8">
          <div class="mb-8">
            <span class="text-gray-400">Contributed by </span>
            <span class="font-bold">{{ dataDetail.contributed }}</span>
          </div>
          <div class="w-full overflow-hidden entry-content" v-html="dataDetail.detail"></div>
        </div>
        <div
          class="lg:absolute sticky top-24 border-t border-gray-400 flex flex-col gap-y-2 py-8 lg:py-4"
        >
          <div class="text-lg font-bold" style="font-family: Lora">Share</div>
          <div
            class="h-16 grid grid-rows-2 grid-flow-col gap-2 lg:flex lg:flex-col"
          >
            <div class="flex align-center cursor-pointer text-[#1f252c]">
              <share-network
                class="flex text-[#1f252c]"
                network="facebook"
                :url="url"
                :title="dataDetail.title"
                :description="dataDetail.subtitle"
              >
                <img
                  alt="image"
                  src="/playground_assets/facebook.svg"
                  class="mr-2 w-4 h-4"
                />
                <span class="text-sm">Facebook</span>
              </share-network>
            </div>
            <div class="flex align-center cursor-pointer">
              <share-network
                class="flex text-[#1f252c]"
                network="twitter"
                :url="url"
                :title="dataDetail.title"
              >
                <img
                  alt="image"
                  src="/playground_assets/twitter.svg"
                  class="mr-2 w-4 h-4"
                />
                <span class="text-sm">Twitter</span>
              </share-network>
            </div>
            <div class="flex align-center cursor-pointer">
              <share-network
                class="flex text-[#1f252c]"
                network="whatsapp"
                :url="url"
                :title="dataDetail.title"
                :description="dataDetail.subtitle"
              >
                <img
                  alt="image"
                  src="/playground_assets/whatsapp.svg"
                  class="mr-2 w-4 h-4"
                />
                <span class="text-sm">WhatsApp</span>
              </share-network>
            </div>
            <div class="flex align-center cursor-pointer" @click="copy">
              <img alt="image" src="/playground_assets/link.svg" class="mr-2 w-4 h-4" />
              <span class="text-sm">Copy URL</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden" :style="`background-color: ${dataDetail.color}`">
      <div class="w-full max-w-rifyo mx-auto">
        <div class="py-16 lg:py-20 max-xl:px-7">
          <div
            class="text-center text-white text-4xl lg:text-5xl leading-tight font-bold mb-6 lg:mb-14 font-lora"
          >
            Related Product
          </div>
          <swiper class="swiper overflow-visible" :options="swiperOption">
            <swiper-slide
              v-for="pv in related_product"
              :key="pv.id">
              <product-card
                :slug="pv.product.slug"
                :productimg="pv.product.photo"
                :title="pv.product.title"
              ></product-card>
            </swiper-slide>
            <div class="swiper-button-prev hidden lg:flex" slot="button-prev"></div>
            <div class="swiper-button-next hidden lg:flex" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </div>
    <div v-if="others.length" :style="`background-color: #EAE3D9`">
      <div class="max-w-rifyo w-full mx-auto">
        <div class="py-16 lg:py-20 max-xl:px-7">
          <div
            class="text-center text-4xl lg:text-5xl leading-tight font-bold mb-6 lg:mb-14 font-lora"
          >
            Other Inspiration
          </div>
          <div class="container p-4 flex flex-col lg:flex-row gap-4">
            <div
              class="w-full lg:w-1/3"
              :key="`other-inspiration-${index}`"
              v-for="(item, index) in others"
            >
              <div
                class="bg-white flex-none flex align-center cursor-pointer"
                @click="openOther(item)"
              >
                <img
                  :alt="item.title"
                  class="flex-none object-cover mr-auto w-[120px] h-[120px] lg:w-36 lg:h-36"
                  :src="item.image"
                />
                <div class="p-4 flex flex-col justify-between w-full">
                  <div
                    v-html="item.title.substring(0, 45)"
                    class="flex-none text-lg leading-tight font-bold text-left line-clamp-4 whitespace-normal inspiration-card-text font-lora"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="closeMsg()" class="alert-err">
      <XClose v-if="isError" :text="msg_err" />
    </div>
    <Footer />
  </div>
</template>

<script>
  import InspirationHeaderVector from '../components/vector-inspiration-header';
  import InspirationCard from '../components/inspiration-card';
  import ProductCard from '../components/product-card';
  import PromotionCard from '../components/promotion-card';
  import SpacerW16 from '../components/spacer-w16';
  import XClose from '../components/x-close';
  import Footer from '../components/footer';
  import Api from '../api';
  import { formatRupiah } from '../lib';
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import 'swiper/css/swiper.css';

  export default {
    components: {
      InspirationHeaderVector,
      PromotionCard,
      SpacerW16,
      InspirationCard,
      Footer,
      XClose,
      ProductCard,
      Swiper,
      SwiperSlide
    },

    computed: {
      url() {
        return window.location.href ? window.location.href : null;
        // return `https://rifyo-stage.saga.id${this.$route.fullPath}`;
      },
      others() {
        const array = this.inspirations.filter(
          (element) => element.id != this.dataDetail.id
        );
        if (array.length > 3) {
          let others = [];
          for (let index = 0; index < 3; index++) {
            const i = Math.floor(Math.random() * array.length);
            const element = array[i];
            others.push(element);
            array.splice(i, 1);
          }
          return others;
        } else {
          return array;
        }
      },
    },

    data() {
      return {
        dataDetail: {
          title: '',
          subtitle: '',
          detail: null,
          color: null,
          img_detail: null,
        },
        coba: 'tes',
        isError: false,
        msg_err: null,
        inspirations: [],
        isLoading: false,
        related_product: [],
        swiperOption: {
          slidesPerView: 3,
          spaceBetween: 16,
          pagination: {
            el: '.swiper-pagination',
            type: 'fraction'
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          },
          breakpoints: {
            1440: {
              slidesPerView: 3,
              spaceBetween: 16
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 16
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 16
            },
            320: {
              slidesPerView: 1,
              spaceBetween: 16,
            }
          }
        },
      };
    },

    async created() {
      await this.getInspiration();
      await this.getInspirations();
      await this.getListRelatedProduct();
    },

    methods: {
      openOther(item) {
        this.isLoading = true;
        this.$router.push(`/inspiration-detail/${item.slug}`);
        setTimeout(() => {
          location.reload();
        }, 200);
      },

      log(msg) {
        console.log(msg);
      },

      async getInspirations() {
        try {
          const response = await this.$http.get(Api.inspirationUrl);
          if (response.data.status) {
            this.inspirations = response.data.data;
          } else {
            console.error(response.data.message);
          }
        } catch (error) {
          console.error(error);
        }
      },
      async getListRelatedProduct() {
        try {
          const response = await this.$http.get(Api.productRelated);
          this.related_product = response.data.data.map((f) => {
            return {
              ...f,
              product: {
                ...f.product,
                title: f.product ? f.product.title : '',
                photo: f.product.photo ? f.product.photo : this.photo_default,
              },
            };
          });
        } catch (error) {
          console.log(error);
          this.msg_err = error.message;
          this.isError = true;
        }
      },
      copy() {
        navigator.clipboard.writeText(window.location.href);
        alert('Link copied.');
      },
      async share() {
        try {
          await navigator.share({
            title: this.dataDetail.title,
            text: this.dataDetail.subtitle,
            url: window.location.href,
          });
        } catch (err) {
          alert(
            "Your browser doesn't support this share function. Try again on another browser."
          );
        }
      },
      formatRupiah(value) {
        return formatRupiah(value);
      },
      goBack: function () {
        this.$router.go(-1);
      },
      closeMsg() {
        this.msg_err = null;
        this.isError = false;
      },
      async getInspiration() {
        try {
          const response = await this.$http.get(
            Api.inspirationUrl + '/' + this.$route.params.slug
          );
          this.dataDetail = response.data.data;
        } catch (error) {
          this.msg_err = error.message;
          this.isError = true;
          setTimeout(() => {
            this.isError = false;
          }, 5000);
        }
      },
    },

    name: 'InspirationDetail',
  };
</script>

<style scoped>
  .h-640 {
    height: 640px;
  }
  .swiper-slide {
    width: 311px;
    transition: 0.5s;
  }
  .swiper-button-prev, .swiper-button-next {
    width: 52px;
    height: 52px;
    background: #fff;
    border: 4px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.59);
    border-radius: 9999px;
  }
  .swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: -35px;
  }
  .swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: -25px;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0IDEyLjAwMDJINSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik0xMyAyMS4wMDAyTDYuMTIxMzIgMTQuMTIxNkM0Ljk0OTc0IDEyLjk1IDQuOTQ5NzUgMTEuMDUwNSA2LjEyMTMyIDkuODc4OTJMMTMgMy4wMDAyNCIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
    line-height: 0;
  }
  .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzI5MTRfMjgxOTYpIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOSAxMy4wMDAyTDAgMTMuMDAwMkwwIDExLjAwMDJMMTkgMTEuMDAwMlYxMy4wMDAyWiIgZmlsbD0iIzFGMjUyQyIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE2Ljc1NzQgOS4xNzE5TDExLjI5MyAzLjcwNzQzTDEyLjcwNzIgMi4yOTMyMUwxOC4xNzE3IDcuNzU3NjhDMjAuNTE0OCAxMC4xMDA4IDIwLjUxNDggMTMuODk5OCAxOC4xNzE2IDE2LjI0M0wxMi43MDcyIDIxLjcwNzRMMTEuMjkzIDIwLjI5MzJMMTYuNzU3NCAxNC44Mjg3QzE4LjMxOTUgMTMuMjY2NiAxOC4zMTk1IDEwLjczNCAxNi43NTc0IDkuMTcxOVoiIGZpbGw9IiMxRjI1MkMiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF8yOTE0XzI4MTk2Ij4KPHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSJ3aGl0ZSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAwLjAwMDI0NDE0MSkiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K);
    line-height: 0;
  }
  .swiper-slide-next ~ .swiper-slide ~ .swiper-slide {
    opacity: 0.5;
  }
  .swiper-slide-prev {
    opacity: 0;
  }
  .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
  .alert-err {
    position: fixed;
    right: 20px;
    bottom: 30px;
    width: 300px;
    z-index: 10000;
    background: #fff;
  }
  .inspiration-detail-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    margin-top: 110px;
  }
  .inspiration-detail-back {
    flex: 0 0 auto;
    width: 100%;
    height: var(--dl-size-size-medium);
    display: flex;
    align-items: center;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    background-size: cover;
    cursor: pointer;
  }
  .inspiration-detail-img-back {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
  .inspiration-detail-back-title {
    color: #524538;
    font-family: Montserrat;
    margin-left: var(--dl-space-space-unit);
    letter-spacing: 4px;
  }
  .inspiration-detail-hero-inspiration {
    flex: 0 0 auto;
    width: 100%;
    height: 540px;
    display: flex;
    align-items: flex-start;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    flex-direction: column;
    background-size: cover;
    justify-content: center;
  }
  .inspiration-detail-text {
    color: #fff;
    font-family: Lora;
    line-height: 45px;
    margin-bottom: var(--dl-space-space-threeunits);
    width: 50%;
  }
  .inspiration-detail-text06 {
    color: #fff;
    font-size: 18px;
    font-family: Montserrat;
  }
  .inspiration-detail-blog {
    width: 100%;
    height: 1604px;
    display: flex;
    align-items: flex-start;
  }
  .inspiration-detail-container01 {
    flex: 0 0 auto;
    width: 253px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .inspiration-detail-container02 {
    width: 136px;
    height: 203px;
    display: flex;
    margin-top: var(--dl-space-space-fiveunits);
    align-items: flex-start;
    border-color: rgba(120, 120, 120, 0.4);
    border-style: solid;
    flex-direction: column;
    border-top-width: 1px;
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
  }
  .inspiration-detail-text10 {
    font-size: 18px;
    margin-top: var(--dl-space-space-unit);
    font-family: Lora;
    margin-bottom: var(--dl-space-space-unit);
  }
  .inspiration-detail-text12 {
    font-style: normal;
    font-weight: 700;
  }
  .inspiration-detail-container03 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-bottom: var(--dl-space-space-unit);
  }
  .inspiration-detail-image {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    object-fit: cover;
  }
  .inspiration-detail-text13 {
    color: #1f252c;
    font-size: 14px;
    font-family: Montserrat;
    margin-left: var(--dl-space-space-halfunit);
  }
  .inspiration-detail-container04 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .inspiration-detail-image1 {
    width: var(--dl-size-size-xsmall);
    object-fit: cover;
  }
  .inspiration-detail-text15 {
    color: #1f252c;
    font-size: 14px;
    font-family: Montserrat;
    margin-left: var(--dl-space-space-halfunit);
  }
  .inspiration-detail-container05 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-unit);
    align-items: flex-start;
  }
  .inspiration-detail-image2 {
    width: var(--dl-size-size-xsmall);
    height: var(--dl-size-size-xsmall);
    object-fit: cover;
  }
  .inspiration-detail-text17 {
    color: #1f252c;
    font-size: 14px;
    font-family: Montserrat;
    margin-left: var(--dl-space-space-halfunit);
  }
  .inspiration-detail-container06 {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    margin-right: var(--dl-space-space-fiveunits);
    padding-top: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .inspiration-detail-text18 {
    color: #1f252c;
    font-size: 18px;
    margin-top: var(--dl-space-space-fiveunits);
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .inspiration-detail-image3 {
    width: 100%;
    object-fit: cover;
  }
  .inspiration-detail-text19 {
    color: #1f252c;
    font-size: 14px;
    margin-top: var(--dl-space-space-twounits);
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .inspiration-detail-text20 {
    color: #1f252c;
    font-family: Montserrat;
    line-height: 1.5;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .inspiration-detail-container07 {
    width: 100%;
    height: 428px;
    display: flex;
    align-items: center;
    background-size: cover;
    justify-content: center;
    background-image: url('/playground_assets/blog-video.png');
  }
  .inspiration-detail-image4 {
    width: 100px;
    object-fit: cover;
  }
  .inspiration-detail-text21 {
    color: #1f252c;
    font-size: 14px;
    margin-top: var(--dl-space-space-twounits);
    font-family: Montserrat;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .inspiration-detail-container08 {
    flex: 0 0 auto;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: flex-start;
  }
  .inspiration-detail-text22 {
    font-family: Montserrat;
  }
  .inspiration-detail-text23 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 700;
    margin-left: var(--dl-space-space-halfunit);
  }
  .inspiration-detail-related-product {
    width: 100%;
    height: 434px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #6acecc;
  }
  .inspiration-detail-container09 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .inspiration-detail-text26 {
    color: #fff;
    font-size: 3em;
    font-family: Lora;
  }
  .inspiration-detail-text27 {
    color: #fff;
    font-size: 3em;
    font-family: freehand;
    margin-left: var(--dl-space-space-unit);
  }
  .inspiration-detail-container10 {
    width: 100%;
    display: flex;
    flex: 0 0 30%;
    margin-top: var(--dl-space-space-twounits);
    align-items: flex-start;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    justify-content: space-between;
    gap: 16px;
  }
  .inspiration-detail-other-inspiration {
    width: 100%;
    height: 434px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #eae3d9;
  }
  .inspiration-detail-container11 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inspiration-detail-text28 {
    color: #1f252c;
    font-size: 3em;
    font-family: Lora;
  }
  .inspiration-detail-text29 {
    color: #1f252c;
    font-size: 3em;
    font-family: freehand;
    margin-left: var(--dl-space-space-unit);
  }
  .inspiration-detail-container12 {
    width: 100%;
    height: 50%;
    display: flex;
    position: relative;
    align-items: center;
    padding-left: var(--dl-space-space-fiveunits);
    padding-right: var(--dl-space-space-fiveunits);
    justify-content: space-between;
  }

  @media (max-width: 991px) {
    .inspiration-detail-back {
      padding-left: 0;
      padding-right: 0;
      justify-content: flex-end;
    }
    .inspiration-detail-hero-inspiration {
      padding-left: 16px;
      padding-right: 16px;
    }
    .inspiration-detail-text {
      color: #fff;
      font-family: Lora;
      line-height: normal;
      margin-bottom: 24px;
      font-size: 24px;
    }
    .inspiration-detail-blog {
      flex-wrap: wrap;
    }
    .inspiration-detail-container11 {
      margin-bottom: 32px;
    }
    .inspiration-detail-text06 {
      font-size: 16px;
    }
    .inspiration-detail-container01 {
      order: 2;
    }
    .inspiration-detail-container06 {
      order: 1;
      flex-basis: 100%;
      margin-right: 0px;
      padding: 16px;
    }
    .inspiration-detail-container12 {
      flex-wrap: wrap;
      padding-left: 16px;
      padding-right: 16px;
      height: auto;
    }
    .inspiration-detail-other-inspiration {
      height: auto;
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .inspiration-detail-container10[data-v-e16608de] {
      width: 100%;
      display: flex;
      flex: 0 1 33%;
      margin-top: var(--dl-space-space-twounits);
      align-items: flex-start;
      padding-left: 16px;
      padding-right: 0px;
      overflow-y: scroll;
    }
    .inspiration-detail-text {
      width: 100%;
    }
  }
</style>
