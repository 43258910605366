<template>
  <div
    class="inspiration-item col-span-4 md:col-span-6 lg:col-span-4 cursor-pointer transition-shadow hover:shadow"
    :class="rootClassName"
  >
    <div
      class="inspiration-item__wrapper flex flex-wrap flex-row items-center h-full"
    >
      <div
        class="inspiration-card-image"
        :style="{ 'background-color': color }"
      >
        <img
          :alt="image_alt"
          :src="imageSrc"
          class="w-full h-full object-cover"
        />
      </div>
      <div class="inspiration-card-content">
        <div
          v-html="desc.substring(0, 45)"
          class="inspiration-card-text line-clamp-4"
        ></div>
      </div>
    </div>
    <div
      class="inspiration-border"
      :style="{ 'background-color': color }"
    ></div>
    <router-link
      :to="`/inspiration-detail/${slug}`"
      class="after:absolute after:inset-0"
    >
      <span class="sr-only">detail inpiration</span>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'InspirationCard',
    props: {
      desc: {
        type: String,
        default:
          'Which one will melt your heart? A baby’s first time cry or its beautiful smile?',
      },
      color: {
        type: String,
        default: '#ED701B',
      },
      imageSrc: {
        type: String,
        default: '/playground_assets/default-img.svg',
      },
      image_alt: {
        type: String,
        default: 'image',
      },
      rootClassName: String,
      slug: String,
    },
  };
</script>

<style scoped>
  .inspiration-item {
    position: relative;
    border: 1px solid #eae3d9;
    box-sizing: border-box;
  }
  .inspiration-card-image {
    width: 144px;
    height: 100%;
    max-height: 144px;
  }
  .inspiration-card-content {
    position: relative;
    width: calc(100% - 144px);
  }
  .inspiration-card-text {
    font-size: 18px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    line-height: 24px;
    padding: 24px;
    padding-left: 30px;
    color: #1f252c;
  }
  .inspiration-border {
    position: absolute;
    height: 100%;
    width: 8px;
    z-index: 10;
    top: 0;
    left: 0;
  }
</style>
