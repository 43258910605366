<template>
  <svg width="188" height="640" viewBox="0 0 188 640" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 639.308C0.400513 639.343 0.801239 639.376 1.20215 639.408C26.8091 641.441 52.547 637.744 76.5492 628.586C100.551 619.427 122.216 605.037 139.971 586.458C157.727 567.88 171.127 545.579 179.202 521.173C187.277 496.768 189.822 470.869 186.654 445.357C183.487 419.844 174.685 395.357 160.887 373.672C147.09 351.986 128.642 333.647 106.883 319.983C128.647 306.32 147.099 287.981 160.9 266.295C174.702 244.609 183.507 220.119 186.676 194.604C189.846 169.089 187.301 143.188 179.226 118.779C171.151 94.3706 157.748 72.0673 139.99 53.4873C122.232 34.9073 100.564 20.5166 76.5581 11.3593C52.5525 2.20193 26.8111 -1.49237 1.20163 0.544398C0.800903 0.57627 0.40036 0.609531 0 0.644182V639.308Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#FFFFFF'
    }
  }
}
</script>